import { FaqProps } from "@ft/components/common/Faq";

const GeneratorRandomPasswordFaq: FaqProps[] = [
  {
    question: "What does a Random Password Generator do?",
    ans: "It is a tool designed to create highly secure and unpredictable passwords, helping to strengthen online protection.",
  },
  {
    question: "How does this tool create passwords?",
    ans: "It utilizes an advanced algorithm to generate strong, unique passwords by combining letters, numbers, and special characters based on your chosen settings.",
  },
  {
    question: "What are the benefits of using a Random Password Generator?",
    ans: "It enhances security by generating complex passwords that are hard to crack, reducing the risk of unauthorized access.",
  },
  {
    question: "Is this Password Generator safe to use?",
    ans: "Absolutely! The tool generates passwords on your device without storing or transmitting any data.",
  },
  {
    question: "Is this tool free to use?",
    ans: "Yes, our Random Password Generator is 100% free and requires no registration.",
  },
  {
    question: "Is this tool compatible with mobile devices?",
    ans: "Yes, our Password Generator is fully responsive and works smoothly on smartphones, tablets, and desktops.",
  },
];
export default GeneratorRandomPasswordFaq;
