export enum ToolKeys {
  SalaryHike = "SalaryHike",
  StringToASCII = "StringToASCII",
  ASCIItoString = "ASCIItoString",
  JSONtostring = "JSONtostring",
  JSONParser = "JSONParser",
  JSON_VALIDATOR = "JSON_VALIDATOR",
  JSONUnstringify = "JSONUnstringify",
  UppercaseTextConverter = "UppercaseTextConverter",
  LowercaseTextconverter = "LowercaseTextconverter",
  wordCounter = "wordCounter",
  Tools = "Tools",
  ReplaceSpaces = "ReplaceSpaces",
  UrlDecode = "UrlDecode",
  URLEncode = "URLEncode",
  UUIDGenerator = "UUIDGenerator",
  HOME = "HOME",
  JSON_DIFF = "JSON_DIFF",
  JSON_MINIFIER = "JSON_MINIFIER",
  JSON_TO_TYPESCRIPT = "JSON_TO_TYPESCRIPT",
  REMOVE_EXTRA_SPACES = "REMOVE_EXTRA_SPACES",
  REMOVE_SPACES = "REMOVE_SPACES",
  XML_FORMATTER = "XML_FORMATTER",
  XML_MINIFIER = "XML_MINIFIER",
  XML_TO_JSON = "XML_TO_JSON",
  JSON_TO_XML = "JSON_TO_XML",
  NUMBER_TO_WORDS = "NUMBER_TO_WORDS",
  GENERATE_RANDOM_STRING = "GENERATE_RANDOM_STRING",
  JSON_EXAMPLES = "JSON_EXAMPLES",
  GENERATE_RANDOM_PWD = "GENERATE_RANDOM_PWD",

}
